import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/cellRenderers/avatarWithNameAndEmail/CustomTag.module.scss'

export const CustomTag = ({ label }: { label: string }) => {
  return (
    <div className={styles.wrapper}>
      <WppTypography type="xs-strong">{label}</WppTypography>
    </div>
  )
}
