import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { getLegacyLocalMicroAppData, getLocalMicroAppData } from 'providers/osState/utils/appData'
import { isValueSet, UnsetValue } from 'providers/osState/utils/conditionalData'
import { getInvalidResolvedAppData } from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { AppDataFromUrlType, LocalLegacyAppDataFromUrl, LocalAppDataFromUrl } from 'types/osState/appDataFromUrl'
import { getLocalAppUrl } from 'utils/navigation'
import { isWorkspaceIdValid, resolveHierarchyPath } from 'utils/workspace'

interface Params {
  appDataShort: LocalAppDataFromUrl | LocalLegacyAppDataFromUrl
}

export const useLocalAppDataResolver = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const { navigationTree, navigationTreeWithHiddenLevel } = useCurrentTenantData()

  const isEnabled = !!params

  // Optimize active hierarchy calculations
  const selectedHierarchyData = useMemo(() => {
    if (isEnabled) {
      const selectedHierarchyId = params.appDataShort.workspaceAzId ?? null
      const isWorkspaceValid = isWorkspaceIdValid({
        workspaceAzId: selectedHierarchyId,
        navigationTree,
      })

      if (isWorkspaceValid) {
        const activeHierarchy = resolveHierarchyPath({
          validHierarchyId: selectedHierarchyId,
          navigationTree,
        })
        const activeHierarchyWithHiddenLevel = resolveHierarchyPath({
          validHierarchyId: selectedHierarchyId,
          navigationTree: navigationTreeWithHiddenLevel,
        })

        return {
          selectedHierarchyId,
          isWorkspaceValid,
          activeHierarchy,
          activeHierarchyWithHiddenLevel,
        }
      }

      return {
        selectedHierarchyId,
        isWorkspaceValid,
        activeHierarchy: null,
        activeHierarchyWithHiddenLevel: null,
      }
    }

    return UnsetValue
  }, [isEnabled, navigationTree, navigationTreeWithHiddenLevel, params?.appDataShort.workspaceAzId])

  if (isEnabled && isValueSet(selectedHierarchyData)) {
    const { selectedHierarchyId, isWorkspaceValid, activeHierarchy, activeHierarchyWithHiddenLevel } =
      selectedHierarchyData
    const { type, app } = params.appDataShort

    if (isWorkspaceValid) {
      const currentBaseUrl = getLocalAppUrl({
        workspaceAzId: selectedHierarchyId,
        osRoute: app.osRoute,
      }).slice(1)

      if (type === AppDataFromUrlType.LocalApp) {
        return {
          appData: getLocalMicroAppData({
            app,
            currentBaseUrl,
            activeWorkspaceId: selectedHierarchyId,
            activeHierarchy,
            activeHierarchyWithHiddenLevel,
          }),
          sideEffectNode: null,
        }
      } else if (type === AppDataFromUrlType.LocalLegacyApp) {
        return {
          appData: getLegacyLocalMicroAppData({
            app,
            currentBaseUrl,
            activeWorkspaceId: selectedHierarchyId,
            activeHierarchy,
            activeHierarchyWithHiddenLevel,
          }),
          sideEffectNode: null,
        }
      }
    } else {
      console.error(`Invalid hierarchy node id for app '${app.name}'`)
    }

    return getInvalidResolvedAppData()
  }

  return null
}
