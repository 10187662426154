import { MayBeNull, NavigationTree } from '@wpp-open/core'

import { getInvalidAppData, getLoadingAppData } from 'providers/osState/utils/appData'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'

export const getInvalidResolvedAppData = (): ResolvedAppData => ({
  appData: getInvalidAppData(),
  sideEffectNode: null,
})

export const getLoadingResolvedAppData = (
  { sideEffectNode }: Pick<ResolvedAppData, 'sideEffectNode'> = { sideEffectNode: null },
): ResolvedAppData => ({
  appData: getLoadingAppData(),
  sideEffectNode,
})

export const filterAppInstanceWorkspaceId = ({
  workspaceAzId,
  navigationTree,
}: {
  workspaceAzId: MayBeNull<string>
  navigationTree: NavigationTree
}) => (workspaceAzId === navigationTree.rootId ? null : workspaceAzId)
