import {
  Tenant,
  TenantConfigNavigationType,
  MayBeNull,
  NavigationTreeNode,
  HierarchyNode,
  DefaultHierarchyLevelType,
  HierarchyCustomNodeType,
  AppInstanceShort,
  NoCodeAppType,
} from '@wpp-open/core'

import { LOCAL_APP_BASE_URL, LOCAL_APP_DEFAULT_URL_CONFIG } from 'constants/apps'
import { APPLICATION_URL_PREFIX, WORKSPACE_URL_PREFIX } from 'constants/routes'
import { Hub } from 'types/hubs/hubs'
import { mapAppInstanceToApp } from 'utils/appInstances'
import { excludeFalsy, join } from 'utils/common'
import { routesManager } from 'utils/routesManager'

export const getAppInstanceUrl = ({ id, osRoute }: { id: string; osRoute?: string }) => {
  const routeParts = [id, osRoute].filter(excludeFalsy)
  const routePath = join(routeParts, '/')

  return `/${APPLICATION_URL_PREFIX}/${routePath}`
}

export const getAppInstanceFullUrl = (appInstance: AppInstanceShort) => {
  const app = mapAppInstanceToApp(appInstance)
  return app.type === NoCodeAppType.ExternalLink
    ? app.url
    : window.location.origin +
        routesManager.app({
          id: app.stableId,
          osRoute: app.osRoute,
        })
}

export const getLocalAppUrl = ({ workspaceAzId, osRoute }: { workspaceAzId?: MayBeNull<string>; osRoute: string }) => {
  const workspacePart = workspaceAzId ? `/${WORKSPACE_URL_PREFIX}/${workspaceAzId}` : ''

  return `${workspacePart}/${osRoute}`
}

export const getDefaultLocalAppUrl = (workspaceAzId?: MayBeNull<string>) =>
  getLocalAppUrl({
    workspaceAzId,
    osRoute: `${LOCAL_APP_BASE_URL}/${LOCAL_APP_DEFAULT_URL_CONFIG}`,
  })

export const getHomeUrl = (defaultHub?: MayBeNull<Hub>) =>
  defaultHub ? routesManager.hubs.root.url(defaultHub.id) : routesManager.home.root.url()

export const getNavigationTypeFlags = ({
  type,
  currentTenant,
}: {
  currentTenant: Tenant
  type: TenantConfigNavigationType
}) => currentTenant.config.navigation.find(configNavigation => configNavigation.type === type)!.flags

export const isNavigationTypeEnabled = ({
  type,
  currentTenant,
}: {
  currentTenant: Tenant
  type: TenantConfigNavigationType
}) => getNavigationTypeFlags({ type, currentTenant }).enabled

export const isHierarchyNode = (node: NavigationTreeNode): node is HierarchyNode =>
  node.type === HierarchyCustomNodeType ||
  Object.values(DefaultHierarchyLevelType).includes(node.type as DefaultHierarchyLevelType)
