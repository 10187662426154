import { UserDetails } from '@wpp-open/core'

import { UserDetailsShort } from 'types/users/users'
import { isString } from 'utils/common'

// TODO: Backend has strange typing for the preferredHubPerTenant field.
//  Empty object can mean there isn't a preferred hub for specific tenant.
export const getPreferredHubId = ({ userDetails, tenantId }: { userDetails: UserDetails; tenantId: string }) => {
  const preferredHubId = userDetails.preferredHubPerTenant?.[tenantId]

  return isString(preferredHubId) ? preferredHubId : undefined
}

export const hasExternalUser = (users: (UserDetailsShort | { email: string; firstname: string; lastname: string })[]) =>
  users.some(user => 'isExternal' in user && user?.isExternal)
