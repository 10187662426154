import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useWrikeAuthAuthenticateApi } from 'api/wrike/queries/useWrikeAuthAuthenticateApi'
import { useStableCallback } from 'hooks/useStableCallback'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { WrikeAuthParams, WrikeAuthPostEvent } from 'types/wrike'
import { safeParseJSON } from 'utils/common'
import { routesManager } from 'utils/routesManager'

export enum platformNameWrike {
  Core = 'core',
  Orchestration = 'orchestration',
}

const postMessage = (message: WrikeAuthPostEvent) => {
  window.opener.postMessage(message, window.location.origin)
  window.close()
}

export const WrikeAuthCallback = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const params = Object.fromEntries(searchParams.entries()) as Omit<WrikeAuthParams, 'redirectUri'>
  const state = safeParseJSON(atob(params.state))
  const { mutateAsync: handleUpdateUserWrikeAuthToken } = useWrikeAuthAuthenticateApi()

  const isOrchestrationPlatform = state?.platform === platformNameWrike.Orchestration

  const handleSetTokenAndRedirect = useStableCallback(async () => {
    try {
      await handleUpdateUserWrikeAuthToken({
        code: params?.code,
        state: params?.state,
        redirectUri: `${window.location.origin}${routesManager.wrikeAuthCallback.url()}`,
      })

      isOrchestrationPlatform
        ? postMessage({ error: false, wrike: true })
        : navigate(routesManager.user.profile.url(), { state: { type: 'wrike' } })
    } catch (e) {
      isOrchestrationPlatform
        ? postMessage({ error: true, wrike: true })
        : navigate(routesManager.user.profile.url(), { state: { type: 'wrike', e } })
    }
  })

  useEffect(() => {
    handleSetTokenAndRedirect()
  }, [handleSetTokenAndRedirect])

  return <LoadingPage />
}
