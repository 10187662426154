import { environment, isProductionLike } from 'environment'

export const DATADOG_APPLICATION_ID = '5da671db-584a-4e09-8e44-5d726bb4611d'
export const DATADOG_PUBLIC_CLIENT_TOKEN = 'pub96769a0661e0abea14cdfac09f4d8498'
export const DATADOG_SERVICE = 'wpp-core-fe'
export const DATADOG_SESSION_REPLAY_SAMPLE_RATE = 20
export const DATADOG_SESSION_SAMPLE_RATE = 100
export const DATADOG_SITE = 'datadoghq.eu'
export enum DATADOG_PRIVACY_LEVEL {
  ALLOW = 'allow',
  MASK = 'mask',
  MASK_USER_INPUT = 'mask-user-input',
}
export const DATADOG_ENV = isProductionLike() ? environment.ENV : 'dev'
