import { createContext, PropsWithChildren, useContext } from 'react'

import { BreadcrumbProjectAppInstancesData } from 'layout/header/breadcrumbs/project/menuContext/ProjectBreadcrumbsMenuContext'
import { AppInstanceApp } from 'utils/appInstances'

interface ProjectBreadcrumbsAppsContextValue {
  appsMap: Record<string, AppInstanceApp>
  isLoading: boolean
  appsInstances: BreadcrumbProjectAppInstancesData
}

const ProjectBreadcrumbsAppsContext = createContext<ProjectBreadcrumbsAppsContextValue>(null!)

export const useProjectBreadcrumbsApps = () => useContext(ProjectBreadcrumbsAppsContext)

interface Props {
  appsMap: Record<string, AppInstanceApp>
  isLoading: boolean
  appsInstances: BreadcrumbProjectAppInstancesData
}

export const ProjectBreadcrumbsAppsProvider = ({
  children,
  appsMap,
  isLoading,
  appsInstances,
}: PropsWithChildren<Props>) => (
  <ProjectBreadcrumbsAppsContext.Provider
    value={{
      appsMap,
      isLoading,
      appsInstances,
    }}
  >
    {children}
  </ProjectBreadcrumbsAppsContext.Provider>
)
