import { MayBeNull } from '@wpp-open/core'

import { ANALYTICS_EVENTS } from 'constants/analytics'
import { IdentityProvider } from 'types/auth/identityProvider'
import { TenantIdp, TenantIdpAlias } from 'types/tenants/tenant'
import { excludeFalsy } from 'utils/common'

interface FilteredTenantIdps {
  emailIdp: MayBeNull<TenantIdp>
  signUpOption: MayBeNull<TenantIdp>
  restIdps: TenantIdp[]
}

export const getFilteredTenantIdps = ({
  isLoading,
  defaultIdps,
  tenantIdps,
}: {
  isLoading: boolean
  defaultIdps: TenantIdp[]
  tenantIdps: TenantIdp[]
}): FilteredTenantIdps => {
  if (isLoading) {
    return { emailIdp: null, signUpOption: null, restIdps: [] }
  }

  const intersected: TenantIdp[] = defaultIdps
    // Magic links are not implemented yet
    .filter(({ alias }) => alias !== TenantIdpAlias.MagicLink)
    .map(defaultIdp => {
      const tenantIdp = tenantIdps.find(
        tenantIdp => tenantIdp.alias.toLocaleLowerCase() === defaultIdp.alias.toLocaleLowerCase(),
      )

      if (tenantIdp) {
        return {
          ...tenantIdp,
          logo: tenantIdp.logo ? tenantIdp.logo : defaultIdp.logo,
        }
      }

      return null
    })
    .filter(excludeFalsy)

  const availableIdps = (tenantIdps.length ? intersected : defaultIdps).filter(({ enabled }) => enabled)

  return {
    emailIdp: availableIdps.find(idp => idp!.alias === TenantIdpAlias.Email) || null,
    signUpOption: availableIdps.find(idp => idp!.alias === TenantIdpAlias.SignUp) || null,
    restIdps: availableIdps.filter(idp => idp.alias !== TenantIdpAlias.Email && idp.alias !== TenantIdpAlias.SignUp),
  }
}

export const mapTenantIdpAliasToAuthIdp = (alias: TenantIdpAlias): IdentityProvider | undefined => {
  switch (alias) {
    case TenantIdpAlias.Okta:
      return IdentityProvider.Okta
    case TenantIdpAlias.GroupM:
      return IdentityProvider.GroupM
    case TenantIdpAlias.IBM:
      return IdentityProvider.IBM
    case TenantIdpAlias.MagicLink:
      return IdentityProvider.MagicLink
    default:
      return undefined
  }
}

export const getIdpsAnalyticsPayload = (alias: TenantIdpAlias) => {
  switch (alias) {
    case TenantIdpAlias.Okta:
      return ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.ACTIONS.OKTA
    case TenantIdpAlias.GroupM:
      return ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.ACTIONS.GROUP_M
    case TenantIdpAlias.IBM:
      // TODO: Add proper analytics value
      return null
    case TenantIdpAlias.MagicLink:
      return ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.ACTIONS.MAGIC_LINK
    default:
      return null
  }
}
