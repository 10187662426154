import { ListValue, SelectOption, SelectTypes } from '@platform-ui-kit/components-library'
import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useRef, forwardRef, Ref, useImperativeHandle } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { Select, SelectProps } from 'components/common/select/Select'
import { useField } from 'hooks/form/useField'

export type FormSelectOption = SelectOption

export interface FormSelectProps<O extends SelectOption, V extends ListValue>
  extends Omit<SelectProps<O, V>, 'onChange' | 'value'> {
  name: string
  type?: SelectTypes
  search?: string
  'data-testid'?: string
}

export const FormSelect = forwardRef(function FormSelect<O extends SelectOption, V extends ListValue>(
  {
    name,
    message,
    messageType,
    'data-testid': dataTestId,
    type = 'single',
    search,
    onWppChange,
    dropdownConfig,
    ...rest
  }: FormSelectProps<O, V>,
  ref: Ref<HTMLWppSelectElement>,
) {
  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })

  const innerRef = useRef<HTMLWppSelectElement>(null)

  useImperativeHandle(
    fieldRef,
    () => ({
      focus: () => innerRef.current?.setFocus(),
    }),
    [],
  )

  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText

  return (
    <Select
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      name={name}
      type={type}
      value={value}
      search={search}
      dropdownConfig={{
        ...dropdownConfig,
        onHidden: instance => {
          dropdownConfig?.onHidden?.(instance)
          onBlur()
        },
      }}
      messageType={shouldShowError ? 'error' : messageType}
      message={shouldShowError ? errorText : message}
      onWppChange={e => {
        onChange(e.detail.value)
        onWppChange?.(e)
      }}
      data-testid={dataTestId}
    />
  )
}) as <O extends SelectOption, V extends ListValue>(props: FormSelectProps<O, V>) => JSX.Element

export const FormSelectSkeleton = () => (
  <Flex gap={8} direction="column">
    <WppSkeleton width="30%" height={22} />
    <WppSkeleton height={40} />
  </Flex>
)
