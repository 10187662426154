import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { showRequestHubModal } from 'components/modal/requestHubModal/RequestHubModal'
import { SvgEmptyList } from 'components/svg/SvgEmptyList'
import { useRequestableHubs } from 'hooks/useRequestableHubs'
import { FullHeightPage } from 'layout/fullHeightPage/FullHeightPage'
import { PageContent } from 'layout/pageContent/PageContent'
import styles from 'pages/hubs/noAvailableHubs/NoAvailableHubs.module.scss'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { getHomeUrl } from 'utils/navigation'

export const NoAvailableHubs = () => {
  const { t } = useTranslation()
  const { defaultHub } = useCurrentTenantData()
  const { requestableHubs } = useRequestableHubs()

  if (defaultHub) {
    return <Navigate replace to={getHomeUrl(defaultHub)} />
  }

  const hasRequestableHubs = !!requestableHubs.length

  return (
    <PageContent>
      <FullHeightPage>
        <Flex className={styles.root} direction="column" align="center" justify="center" gap={16}>
          <SvgEmptyList />
          <div className={styles.textWrapper}>
            <WppTypography type="m-body">{t('os.home.no_available_hubs.message')}</WppTypography>
          </div>
          {hasRequestableHubs && (
            <WppButton
              onClick={() => {
                showRequestHubModal({
                  title: t('os.request_access_modal.hub.title'),
                  hubs: requestableHubs,
                })
              }}
            >
              {t('os.home.no_available_hubs.request_button')}
            </WppButton>
          )}
        </Flex>
      </FullHeightPage>
    </PageContent>
  )
}
