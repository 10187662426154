import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'

import { Delay } from 'constants/delay'
import { useToast } from 'providers/toast/ToastProvider'

export const OsStateAppInstanceFixRoute = ({
  isAppRouteEmpty,
  toPathname,
}: {
  isAppRouteEmpty: boolean
  toPathname: string
}) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { search, hash } = useLocation()

  useEffect(() => {
    if (!isAppRouteEmpty) {
      enqueueToast({
        duration: Delay.ToastLong,
        header: t('os.provider_errors.app_instance_incorrect_route_header'),
        message: t('os.provider_errors.app_instance_incorrect_route_body'),
        type: 'warning',
      })
    }
  }, [enqueueToast, isAppRouteEmpty, t])

  return (
    <Navigate
      replace
      to={{
        pathname: toPathname,
        search,
        hash,
      }}
    />
  )
}
