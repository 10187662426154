import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateHierarchyAccessRequestApi } from 'api/tenant/mutation/useCreateHierarchyAccessRequestApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { MAX_CHARACTERS, useValidationScheme } from 'components/modal/requestHierarchyAccessModal/utils'
import { Modal } from 'components/surface/modal/Modal'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'providers/toast/ToastProvider'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  tenantId: string
}

const RequestHierarchyAccessModal = ({ isOpen, tenantId, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const { mutateAsync: handleAccessRequest, isLoading } = useCreateHierarchyAccessRequestApi()

  const form = useForm({
    defaultValues: { requestReason: '' },
    validationSchema: useValidationScheme(),
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      await handleAccessRequest({
        tenantId,
        requestReason: values.requestReason,
      })

      enqueueToast({
        message: t('os.common.toasts.request_access_navigation'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.error'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <Modal
        formConfig={{ onSubmit }}
        open={isOpen}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('os.request_access_modal.hierarchy.title')}
        </WppTypography>

        <div slot="body">
          <FormTextareaInput
            required
            labelConfig={{ text: t('os.request_access_modal.hierarchy.request_reason.label') }}
            name="requestReason"
            placeholder={t('os.request_access_modal.hierarchy.request_reason.placeholder')}
            charactersLimit={MAX_CHARACTERS}
            warningThreshold={MAX_CHARACTERS}
          />
        </div>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" size="s" onClick={onClose}>
            {t('os.common.cancel')}
          </WppButton>
          <WppButton type="submit" variant="primary" size="s" disabled={!isValid} loading={isLoading}>
            {t('os.request_access_modal.workspace.send_request')}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRequestHierarchyAccessModal } = createNiceModal(
  RequestHierarchyAccessModal,
  'request-hierarchy-access-modal',
)
