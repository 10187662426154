import {
  CompactAppContext,
  FullscreenAppContext,
  HierarchyNodeType,
  MayBeNull,
  NavigationTree,
  SelectedHierarchy,
  SelectedHierarchyItem,
} from '@wpp-open/core'
import { useMemo } from 'react'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { usePublicData } from 'providers/publicData/PublicDataContext'
import { WorkspaceHierarchyIds } from 'utils/workspace'

export const useFullscreenAppContextValue = (): FullscreenAppContext => {
  const { appData } = useOsState()
  const { userDetails } = useOtherTenantsAndUserData()
  const { currentTenant, navigationTreeWithHiddenLevel, permissions } = useCurrentTenantData()
  const { resolvedTheme, currentTaxonomy } = usePublicData()
  const { activePage } = useOsRoot()
  const {
    activeWorkspaceId,
    activeHierarchyWithHiddenLevel,
    currentBaseUrl,
    project,
    projectItem,
    appCustomConfig,
    additionalContext,
    appInstance,
  } = appData

  const selectedWorkspace = useMemo(
    () =>
      resolveSelectedWorkspace({ activeWorkspaceId, activeHierarchyWithHiddenLevel, navigationTreeWithHiddenLevel }),
    [activeHierarchyWithHiddenLevel, activeWorkspaceId, navigationTreeWithHiddenLevel],
  )
  const selectedHierarchy = useMemo(
    () =>
      resolveSelectedHierarchy({ activeWorkspaceId, activeHierarchyWithHiddenLevel, navigationTreeWithHiddenLevel }),
    [activeHierarchyWithHiddenLevel, activeWorkspaceId, navigationTreeWithHiddenLevel],
  )

  return useMemo<FullscreenAppContext>(() => {
    const projectData: FullscreenAppContext['project'] =
      project && projectItem
        ? {
            id: project.id,
            name: project.name,
            type: project.type,
            itemId: String(projectItem.id),
            itemCompleteState: projectItem.task?.status ?? null,
          }
        : null

    return {
      baseUrl: currentBaseUrl,
      workspace: selectedWorkspace,
      hierarchy: selectedHierarchy,
      project: projectData,
      permissions,
      tenant: currentTenant,
      navigationTree: navigationTreeWithHiddenLevel,
      userDetails,
      theme: resolvedTheme,
      taxonomy: currentTaxonomy,
      appInstance,
      appCustomConfig,
      additional: additionalContext,
      activePage,
    }
  }, [
    activePage,
    additionalContext,
    appCustomConfig,
    appInstance,
    selectedWorkspace,
    selectedHierarchy,
    currentBaseUrl,
    currentTaxonomy,
    currentTenant,
    navigationTreeWithHiddenLevel,
    permissions,
    project,
    projectItem,
    resolvedTheme,
    userDetails,
  ])
}

export const useCompactAppContextValue = (): CompactAppContext => {
  const { userDetails } = useOtherTenantsAndUserData()
  const { currentTenant, navigationTreeWithHiddenLevel, permissions } = useCurrentTenantData()
  const { resolvedTheme, currentTaxonomy } = usePublicData()
  const { activePage } = useOsRoot()

  return useMemo<CompactAppContext>(() => {
    return {
      permissions,
      tenant: currentTenant,
      navigationTree: navigationTreeWithHiddenLevel,
      userDetails,
      theme: resolvedTheme,
      taxonomy: currentTaxonomy,
      activePage,
    }
  }, [
    activePage,
    currentTaxonomy,
    currentTenant,
    navigationTreeWithHiddenLevel,
    permissions,
    resolvedTheme,
    userDetails,
  ])
}

/**
 * @deprecated Will be removed along with the `FullscreenAppContext.workspace` field
 */
export const resolveSelectedWorkspace = ({
  activeWorkspaceId,
  activeHierarchyWithHiddenLevel,
  navigationTreeWithHiddenLevel,
}: {
  activeWorkspaceId: MayBeNull<string>
  activeHierarchyWithHiddenLevel: WorkspaceHierarchyIds
  navigationTreeWithHiddenLevel: NavigationTree
}): MayBeNull<SelectedHierarchy> => {
  if (!activeWorkspaceId) {
    return null
  }

  return {
    azId: activeWorkspaceId,
    mapping: Object.fromEntries(
      activeHierarchyWithHiddenLevel.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : activeHierarchyWithHiddenLevel[index - 1],
          navigationTreeWithHiddenLevel,
        }),
      ]),
    ),
  }
}

export const resolveSelectedHierarchy = ({
  activeWorkspaceId,
  activeHierarchyWithHiddenLevel,
  navigationTreeWithHiddenLevel,
}: {
  activeWorkspaceId: MayBeNull<string>
  activeHierarchyWithHiddenLevel: WorkspaceHierarchyIds
  navigationTreeWithHiddenLevel: NavigationTree
}): SelectedHierarchy => {
  const { rootId } = navigationTreeWithHiddenLevel
  const selectedRootAzId = activeWorkspaceId || rootId
  // Include tenant level in provided context
  const selectedHierarchyIds = [rootId, ...activeHierarchyWithHiddenLevel]

  return {
    azId: selectedRootAzId,
    mapping: Object.fromEntries(
      selectedHierarchyIds.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : selectedHierarchyIds[index - 1],
          navigationTreeWithHiddenLevel,
        }),
      ]),
    ),
  }
}

const getHierarchyStructureItem = ({
  azId,
  parentAzId,
  navigationTreeWithHiddenLevel,
}: {
  azId: string
  parentAzId?: string
  navigationTreeWithHiddenLevel: NavigationTree
}): SelectedHierarchyItem => {
  const node = navigationTreeWithHiddenLevel.mapping[azId]

  return {
    azId,
    name: node.name,
    type: node.type as HierarchyNodeType,
    customTypeName: node.customTypeName,
    parentAzId,
  }
}
