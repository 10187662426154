import { AppErrorType, OpenAppHandler, TriggerErrorHandler } from '@wpp-open/core'
import { useNavigate } from 'react-router-dom'

import { RenderErrorType } from 'components/renderError'
import { useDispatchRenderError } from 'components/renderError/utils'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useStableCallback } from 'hooks/useStableCallback'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOsState } from 'providers/osState/OsStateProvider'
import { SystemAppCode } from 'types/apps/microApps'
import { SourceLocations } from 'types/osState/appLaunchData'
import { getAppInstanceUrl } from 'utils/navigation'

export const useOpenApp = (): OpenAppHandler => {
  const navigate = useNavigate()
  const { appData, setAppLaunchData } = useOsState()

  return useStableCallback(async (appInstanceId, params) => {
    if (!appInstanceId) {
      throw new Error('OpenAppHandler: appInstanceId is empty')
    }

    let source = null

    if (appData.app?.stableId === SystemAppCode.Orchestration) {
      source = SourceLocations.Orchestration
    }

    setAppLaunchData({
      appInstanceId,
      source,
      launchedFrom: params?.analytics?.launchedFrom || '',
    })

    const appUrl = getAppInstanceUrl({ id: appInstanceId })

    await queryClient.resetQueries({ queryKey: [ApiQueryKeys.APP_INSTANCE, { id: appInstanceId }] })

    navigate(appUrl)
  })
}

const mapAppErrorType = (errorType: AppErrorType): RenderErrorType => {
  switch (errorType) {
    case AppErrorType.ForbiddenPage:
      return RenderErrorType.ForbiddenPage
    case AppErrorType.CriticalError:
      return RenderErrorType.CriticalError
    default:
      throw new Error('Unsupported AppErrorType')
  }
}

export const useTriggerError = (): TriggerErrorHandler => {
  const dispatchError = useDispatchRenderError()

  return useStableCallback(async (errorType: AppErrorType) => {
    dispatchError(mapAppErrorType(errorType))
  })
}
