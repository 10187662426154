import { WppButton, WppModal, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/modal/requestHubModal/HubAlreadyRequestedModal.module.scss'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  hubName: string
}

export const HubAlreadyRequestedModal = ({ id, isOpen, onClose, onCloseComplete, hubName }: Props) => {
  const { t } = useTranslation()

  return (
    <WppModal
      data-testid={id}
      open={isOpen}
      disableOutsideClick
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
    >
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          {t('os.request_access_modal.hub_already_requested.title')}
        </Flex>
      </WppTypography>

      <Flex slot="body" direction="column" gap={16}>
        <WppTypography type="s-body" data-testid="body-text" className={styles.body}>
          <Trans
            i18nKey="os.request_access_modal.hub_already_requested.description"
            values={{
              hubName,
            }}
            components={[
              <WppTypography key="0" type="s-strong">
                hubName
              </WppTypography>,
            ]}
          />
        </WppTypography>
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="primary" size="s" data-testid="confirm">
          {t('os.request_access_modal.hub_already_requested.confirm_button')}
        </WppButton>
      </Flex>
    </WppModal>
  )
}

export const { showModal: showHubAlreadyRequestedModal } = createNiceModal(
  HubAlreadyRequestedModal,
  'hub-already-requested-modal',
)
