import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getAppInstanceDataFromUrl } from 'providers/osState/utils/appDataFromUrl/getAppInstanceDataFromUrl'
import { getSystemAppDataFromUrl } from 'providers/osState/utils/appDataFromUrl/getSystemAppDataFromUrl'
import { useLocalAppDataFromUrl } from 'providers/osState/utils/appDataFromUrl/useLocalAppDataFromUrl'
import { useLocalContextFromUrl } from 'providers/osState/utils/appDataFromUrl/useLocalContextFromUrl'
import { useLocalLegacyAppDataFromUrl } from 'providers/osState/utils/appDataFromUrl/useLocalLegacyAppDataFromUrl'
import { AppDataFromUrl, AppDataFromUrlType, InvalidDataFromUrl } from 'types/osState/appDataFromUrl'

export const useAppDataFromUrl = (): AppDataFromUrl => {
  const { pathname } = useLocation()
  const localContext = useLocalContextFromUrl()
  const localAppDataFromUrl = useLocalAppDataFromUrl(localContext)
  const localLegacyAppDataFromUrl = useLocalLegacyAppDataFromUrl(localContext)

  return useMemo(
    () =>
      localAppDataFromUrl ||
      localLegacyAppDataFromUrl ||
      getSystemAppDataFromUrl({ pathname }) ||
      getAppInstanceDataFromUrl({ pathname }) ||
      getInvalidAppDataFromUrl(),
    [localAppDataFromUrl, localLegacyAppDataFromUrl, pathname],
  )
}

const getInvalidAppDataFromUrl = (): InvalidDataFromUrl => ({
  type: AppDataFromUrlType.Invalid,
})
