import styles from 'components/svg/common.module.scss'

export const SvgEmptyList = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="120" height="96" viewBox="0 0 120 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_36391_11468)">
      <g filter="url(#filter0_f_36391_11468)">
        <ellipse cx="61.1244" cy="79.1648" rx="43.0999" ry="7.76486" className={styles.fillPrimary300} />
      </g>
      <path
        d="M63 96.6001C87.8528 96.6001 108 76.4529 108 51.6001C108 26.7473 87.8528 6.6001 63 6.6001C38.1472 6.6001 18 26.7473 18 51.6001C18 76.4529 38.1472 96.6001 63 96.6001Z"
        className={styles.fillPrimary200}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.4 22.3715C93.1574 22.3715 93.7714 21.7575 93.7714 21.0001C93.7714 20.2427 93.1574 19.6287 92.4 19.6287C91.6426 19.6287 91.0286 20.2427 91.0286 21.0001C91.0286 21.7575 91.6426 22.3715 92.4 22.3715ZM92.4 23.4001C93.7255 23.4001 94.8 22.3256 94.8 21.0001C94.8 19.6746 93.7255 18.6001 92.4 18.6001C91.0745 18.6001 90 19.6746 90 21.0001C90 22.3256 91.0745 23.4001 92.4 23.4001Z"
        className={styles.fillPrimary300}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4 93.1713C81.1574 93.1713 81.7714 92.5573 81.7714 91.7999C81.7714 91.0425 81.1574 90.4285 80.4 90.4285C79.6426 90.4285 79.0286 91.0425 79.0286 91.7999C79.0286 92.5573 79.6426 93.1713 80.4 93.1713ZM80.4 94.1999C81.7255 94.1999 82.8 93.1254 82.8 91.7999C82.8 90.4744 81.7255 89.3999 80.4 89.3999C79.0745 89.3999 78 90.4744 78 91.7999C78 93.1254 79.0745 94.1999 80.4 94.1999Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M14.5791 78.0183C15.6351 77.8463 16.4889 78.9152 16.1074 79.9316L15.5903 81.3092C15.2126 82.3151 13.9056 82.5156 13.2311 81.6712L12.317 80.5268C11.6424 79.6823 12.1027 78.4218 13.1478 78.2515L14.5791 78.0183Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M103.24 58.0997C102.994 57.0846 103.925 56.1781 104.937 56.4481L106.973 56.9918C108.008 57.268 108.349 58.5627 107.584 59.308L106.042 60.8082C105.277 61.5535 103.987 61.182 103.735 60.1435L103.24 58.0997Z"
        className={styles.fillPrimary300}
      />
      <path
        d="M60.0038 3.88845C60.3231 2.70385 62.0769 2.70385 62.3962 3.88845L62.5635 4.50928C62.6794 4.93928 63.0362 5.27104 63.488 5.36871L63.8362 5.44401C65.1213 5.72186 65.1213 7.47814 63.8362 7.75599L63.488 7.83129C63.0362 7.92896 62.6794 8.26072 62.5635 8.69073L62.3962 9.31155C62.0769 10.4961 60.3231 10.4961 60.0038 9.31155L59.8365 8.69072C59.7206 8.26072 59.3638 7.92896 58.912 7.83129L58.5638 7.75599C57.2787 7.47813 57.2787 5.72186 58.5638 5.44401L58.912 5.36871C59.3638 5.27104 59.7206 4.93928 59.8365 4.50927L60.0038 3.88845Z"
        className={styles.fillPrimary400}
      />
      <path
        d="M88.8 32.3999H37.2C35.5431 32.3999 34.2 33.743 34.2 35.3999V98.3999C34.2 100.057 35.5431 101.4 37.2 101.4H88.8C90.4569 101.4 91.8 100.057 91.8 98.3999V35.3999C91.8 33.743 90.4569 32.3999 88.8 32.3999Z"
        className={styles.fillWhite}
      />
      <rect x="51" y="40" width="35" height="8" rx="4" className={styles.fillPrimary100} />
      <rect x="51" y="52" width="35" height="8" rx="4" className={styles.fillPrimary100} />
      <rect x="51" y="64" width="35" height="8" rx="4" className={styles.fillPrimary100} />
      <rect x="51" y="76" width="35" height="8" rx="4" className={styles.fillPrimary100} />
      <rect x="51" y="88" width="35" height="8" rx="4" className={styles.fillPrimary100} />
      <rect x="40" y="40" width="8" height="8" rx="4" className={styles.fillPrimary400} />
      <rect x="40" y="52" width="8" height="8" rx="4" className={styles.fillPrimary300} />
      <rect x="40" y="64" width="8" height="8" rx="4" className={styles.fillPrimary300} />
      <rect x="40" y="76" width="8" height="8" rx="4" className={styles.fillPrimary300} />
      <rect x="40" y="88" width="8" height="8" rx="4" className={styles.fillPrimary300} />
      <path
        d="M18.0038 57.2883C18.3231 56.1038 20.0769 56.1038 20.3962 57.2883L20.5635 57.9092C20.6794 58.3392 21.0362 58.6709 21.488 58.7686L21.8362 58.8439C23.1213 59.1218 23.1213 60.878 21.8362 61.1559L21.488 61.2312C21.0362 61.3289 20.6794 61.6606 20.5635 62.0906L20.3962 62.7115C20.0769 63.8961 18.3231 63.896 18.0038 62.7115L17.8365 62.0906C17.7206 61.6606 17.3638 61.3289 16.912 61.2312L16.5638 61.1559C15.2787 60.878 15.2787 59.1218 16.5638 58.8439L16.912 58.7686C17.3638 58.6709 17.7206 58.3392 17.8365 57.9092L18.0038 57.2883Z"
        className={styles.fillPrimary400}
      />
    </g>
    <defs>
      <filter
        id="filter0_f_36391_11468"
        x="-3.74854"
        y="49.6269"
        width="129.746"
        height="59.0758"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_36391_11468" />
      </filter>
      <clipPath id="clip0_36391_11468">
        <rect width="120" height="96" className={styles.fillWhite} />
      </clipPath>
    </defs>
  </svg>
)
