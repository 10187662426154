import useDatadog from 'react-datadog'

import {
  DATADOG_APPLICATION_ID,
  DATADOG_ENV,
  DATADOG_PRIVACY_LEVEL,
  DATADOG_PUBLIC_CLIENT_TOKEN,
  DATADOG_SERVICE,
  DATADOG_SESSION_REPLAY_SAMPLE_RATE,
  DATADOG_SESSION_SAMPLE_RATE,
  DATADOG_SITE,
} from 'constants/datadog'

export const usePerformanceLogs = () => {
  useDatadog({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_PUBLIC_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: DATADOG_ENV,
    sessionSampleRate: DATADOG_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_SESSION_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DATADOG_PRIVACY_LEVEL.MASK_USER_INPUT,
  })
}
