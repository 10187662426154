import { MayBeNull, NavigationTree } from '@wpp-open/core'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useMiroConfigStatusApi } from 'api/miro/queries/useMiroConfigStatusApi'
import { getActiveNodeIdFromAppState } from 'layout/header/headerNavigationProvider/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { MiroConfigStatusResponse } from 'types/miro'
import { NodesMapping, useNodesMapping } from 'utils/mapping/common'

export interface HeaderNavigationContextValue {
  nodesMapping: NodesMapping<NavigationTree>
  activeNodeId: string
  miroConfigStatus: MayBeNull<MiroConfigStatusResponse>
}

const HeaderNavigationContext = createContext<HeaderNavigationContextValue>(null!)
export const useHeaderNavigation = () => useContext(HeaderNavigationContext)

export const HeaderNavigationProvider = ({ children }: PropsWithChildren<{}>) => {
  const { pathname } = useLocation()
  const { data: miroConfigStatus } = useMiroConfigStatusApi()

  const { currentTenant, navigationTree } = useCurrentTenantData()
  const { appData } = useOsState()
  const nodesMapping = useNodesMapping(navigationTree)

  const activeNodeId = useMemo(
    () =>
      getActiveNodeIdFromAppState({
        navigationTree,
        appData,
        pathname,
        currentTenant,
      }),
    [navigationTree, appData, pathname, currentTenant],
  )

  const value: HeaderNavigationContextValue = {
    nodesMapping,
    activeNodeId,
    miroConfigStatus,
  }

  return <HeaderNavigationContext.Provider value={value}>{children}</HeaderNavigationContext.Provider>
}
