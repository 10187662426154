import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'
import { Hub, HubType } from 'types/hubs/hubs'

export interface RequestableHubsFilters {
  id?: string[]
  type?: HubType
}

export type Params = PaginationParams<{
  searchName?: string
  sort?: string
  filters?: RequestableHubsFilters
}>

export type RequestableHubsResponse = PaginatedResponse<Hub>

export const fetchRequestableHubsApi = ({ page, itemsPerPage, searchName, sort, filters }: Params) =>
  facadeApi.get<RequestableHubsResponse>('/hubs/public', {
    params: skipEmptyParams({
      page,
      itemsPerPage,
      sort,
      filter: { searchName, ...filters },
    }),
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
