import { MayBeNull } from '@wpp-open/core'

import { LaunchLocations } from 'constants/analytics'

export interface AppLaunchData {
  appInstanceId: string
  source: MayBeNull<SourceLocations>
  launchedFrom: MayBeNull<LaunchLocations | string>
}

export enum SourceLocations {
  Core = 'CORE',
  Orchestration = 'ORCHESTRATION',
}
