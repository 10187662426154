import { differenceInMinutes } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { ForcedApiError } from 'api/common/types'
import { useAllTenantsApi } from 'api/tenant/queries/useAllTenantsApi'
import { useAvailableTenantsApi } from 'api/tenant/queries/useAvailableTenantsApi'
import { useCurrentUserApi } from 'api/users/queries/useCurrentUserApi'
import { useUserProfileCountriesList } from 'api/users/queries/useUserProfileCountriesList'
import { is400Error, is403Error } from 'api/utils'
import { useIntercomUser } from 'components/intercom/utils'
import { ForbiddenOSAccessError, OsIsNotAvailableError } from 'components/renderError'
import { PageSize } from 'constants/pageSize'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { OtherTenantsAndUserDataContext } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { UserSetupLoadingPage } from 'providers/otherTenantsAndUserData/userSetupLoadingPage/UserSetupLoadingPage'
import { excludeFalsy } from 'utils/common'

const MAX_DIFFERENCE_IN_MINUTES_CREATED_AT = 5

export const OtherTenantsAndUserDataProvider = () => {
  const { t } = useTranslation()

  const {
    isLoading: isUserLoading,
    data: userDetailsInit,
    error: userDetailsError,
    response: userDetailsResponse,
  } = useCurrentUserApi()

  // get a country (Market) list to get a country name by isoAlpha2 which we use in UserDetails to set the country
  const { data: countries, isLoading: isCountriesLoading } = useUserProfileCountriesList({
    params: {
      page: 1,
      itemsPerPage: PageSize.All,
    },
  })

  const country = useMemo(
    () => countries.find(({ isoAlpha2 }) => isoAlpha2 === userDetailsInit?.countryAlpha2Code)?.name || '',
    [countries, userDetailsInit?.countryAlpha2Code],
  )

  const userDetails = useMemo(() => {
    return { ...userDetailsInit!, country: country || 'none' }
  }, [country, userDetailsInit])

  const { data: avatarsDownloadInfo } = useGenerateUserDetailsDownloadUrlsApi({
    params: {
      keys: [userDetails?.avatarOriginal, userDetails?.avatarThumbnail].filter(excludeFalsy).map(({ key }) => key),
    },
    enabled: !!userDetails?.avatarOriginal || !!userDetails?.avatarThumbnail,
  })
  const [avatarOriginalDownloadInfo, avatarThumbnailDownloadInfo] = avatarsDownloadInfo

  useIntercomUser(userDetails)

  const isUserBeingCreated =
    !!userDetails &&
    (userDetailsResponse?.status === 201 ||
      // In the case of any network issues on /me requests, the 201 Status can be missed
      // Suggested that a new user is a user who was created less than 5min ago
      (!!userDetails.createdAt &&
        differenceInMinutes(new Date(), new Date(userDetails.createdAt)) <= MAX_DIFFERENCE_IN_MINUTES_CREATED_AT))

  // Note: Tenant queries are dependent on the user because new users are created
  // during the first call on the backend, so tenants are not available before that happens.
  const {
    data: availableTenants,
    isLoading: isAvailableTenantsLoading,
    isError: isAvailableTenantsError,
  } = useAvailableTenantsApi({
    params: {
      itemsPerPage: PageSize.All,
    },
    enabled: !!userDetails,
    // Long polling while user is being created in several services.
    // No available tenants most likely means the user account is not ready yet.
    forceError: data => isUserBeingCreated && !!data && data.length === 0,
    // Retry for at least 18 * 5000ms = 90s. Plus the time for the actual requests.
    retry: (failureCount, error) => {
      const isEmptyListError = error instanceof ForcedApiError
      const isNotResolvedError = is400Error(error)

      return failureCount <= 18 && isUserBeingCreated && (isEmptyListError || isNotResolvedError)
    },
    retryDelay: 5000,
  })

  const {
    data: allTenants,
    isLoading: isAllTenantsLoading,
    isError: isAllTenantsError,
  } = useAllTenantsApi({
    params: {
      itemsPerPage: PageSize.All,
    },
    enabled: !!userDetails,
  })

  useProviderNoncriticalError({
    isError: isAvailableTenantsError || isAllTenantsError,
    message: t('os.provider_errors.available_tenants'),
  })

  const requestableTenants = useMemo(
    () =>
      (allTenants || [])
        .filter(tenant => !availableTenants?.find(availableTenant => availableTenant.id === tenant.id))
        .filter(({ flags }) => !flags.onlyAdminsAccessible && flags.accessRequestable)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [allTenants, availableTenants],
  )

  const sortedAvailableTenants = useMemo(
    () => availableTenants?.sort((a, b) => a.name.localeCompare(b.name)),
    [availableTenants],
  )

  const isLoading = isAvailableTenantsLoading || isAllTenantsLoading || isUserLoading || isCountriesLoading

  if (isUserBeingCreated && isLoading) {
    return <UserSetupLoadingPage />
  }

  if (userDetailsError) {
    return is403Error(userDetailsError) ? <ForbiddenOSAccessError /> : <OsIsNotAvailableError />
  }

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <OtherTenantsAndUserDataContext.Provider
      value={{
        availableTenants: sortedAvailableTenants!,
        requestableTenants,
        userDetails: {
          ...userDetails!,
          avatarUrl: avatarThumbnailDownloadInfo?.signed_url || avatarOriginalDownloadInfo?.signed_url || null,
        },
      }}
    >
      <Outlet />
    </OtherTenantsAndUserDataContext.Provider>
  )
}
