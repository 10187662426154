import { useRequestableHubsApi } from 'api/hubs/queries/useRequestableHubsApi'
import { PageSize } from 'constants/pageSize'
import { TableDefaults } from 'constants/table'

export const useRequestableHubs = () => {
  const { data: requestableHubs, isLoading: isLoadingRequestableHubs } = useRequestableHubsApi({
    params: {
      sort: 'name',
      itemsPerPage: PageSize.All,
    },
    staleTime: TableDefaults.LoaderStaleTime,
  })

  return { requestableHubs, isLoadingRequestableHubs }
}
