import { MayBeNull } from './common'

export interface SelectedProjectData {
  id: string
  name: string
  type: ProjectType
  itemId: string
  itemCompleteState: MayBeNull<ProjectTaskStatus>
}

export enum ProjectType {
  Miscellaneous = 'MISCELLANEOUS',
  Pitch = 'PITCH',
  Campaign = 'CAMPAIGN',
  Workshop = 'WORKSHOP',
}

export enum ProjectTaskStatus {
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED',
  Undefined = 'UNDEFINED',
}
