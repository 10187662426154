import { AppInstanceAssignmentType, AppInstanceFull, MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { getAppInstanceAppData } from 'providers/osState/utils/appData'
import { isValueSet, UnsetValue } from 'providers/osState/utils/conditionalData'
import { filterAppInstanceWorkspaceId, getInvalidResolvedAppData } from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import {
  formatAppCustomConfig,
  isNoCodeAppInstanceApp,
  isValidAppInstanceHierarchyPath,
  RoutedAppInstanceApp,
} from 'utils/appInstances'
import { isWorkspaceIdValid, resolveHierarchyPath } from 'utils/workspace'

interface Params {
  appInstance: AppInstanceFull
  app: RoutedAppInstanceApp
  currentBaseUrl: string
}

export const useWorkspaceAppInstance = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const { navigationTree, navigationTreeWithHiddenLevel } = useCurrentTenantData()

  const isEnabled = !!params
  const appCustomConfig = useMemo(
    () => (isEnabled ? formatAppCustomConfig(params.appInstance) : UnsetValue),
    [isEnabled, params?.appInstance],
  )

  // Optimize active hierarchy calculations
  const selectedHierarchyData = useMemo(() => {
    if (isEnabled) {
      const assignmentId = params.appInstance.assignmentId
      const isCustomNavigation = params.appInstance.assignmentType === AppInstanceAssignmentType.Custom
      const selectedHierarchyId = isCustomNavigation
        ? null
        : filterAppInstanceWorkspaceId({
            workspaceAzId: assignmentId,
            navigationTree,
          })
      const isWorkspaceValid = isWorkspaceIdValid({
        workspaceAzId: selectedHierarchyId,
        navigationTree,
      })

      if (isWorkspaceValid) {
        const activeHierarchy = resolveHierarchyPath({
          validHierarchyId: selectedHierarchyId,
          navigationTree,
        })
        const activeHierarchyWithHiddenLevel = resolveHierarchyPath({
          validHierarchyId: selectedHierarchyId,
          navigationTree: navigationTreeWithHiddenLevel,
        })

        return {
          selectedHierarchyId,
          isCustomNavigation,
          isWorkspaceValid,
          activeHierarchy,
          activeHierarchyWithHiddenLevel,
        }
      }

      return {
        selectedHierarchyId,
        isWorkspaceValid,
        activeHierarchy: null,
        activeHierarchyWithHiddenLevel: null,
      }
    }

    return UnsetValue
  }, [
    isEnabled,
    navigationTree,
    navigationTreeWithHiddenLevel,
    params?.appInstance.assignmentId,
    params?.appInstance.assignmentType,
  ])

  // Validate and process workspace data
  if (isEnabled && isValueSet(appCustomConfig) && isValueSet(selectedHierarchyData)) {
    const {
      selectedHierarchyId,
      isCustomNavigation,
      isWorkspaceValid,
      activeHierarchy,
      activeHierarchyWithHiddenLevel,
    } = selectedHierarchyData
    const { appInstance, app, currentBaseUrl } = params

    const isNoCodeApp = isNoCodeAppInstanceApp(app)
    const isProjectOnly = !isNoCodeApp && app.isProjectOnly

    const additionalContext = appInstance.context

    const isHierarchyPathValid =
      isWorkspaceValid &&
      isValidAppInstanceHierarchyPath({
        app,
        activeHierarchyWithHiddenLevel,
        mappingWithHiddenLevel: navigationTreeWithHiddenLevel.mapping,
      })

    // isWorkspaceValid is double-checked here for Typescript to correctly resolve activeHierarchy fields
    if (!isProjectOnly && isWorkspaceValid && isHierarchyPathValid) {
      return {
        appData: getAppInstanceAppData({
          app,
          currentBaseUrl,
          activeWorkspaceId: selectedHierarchyId,
          activeHierarchy,
          activeHierarchyWithHiddenLevel,
          appInstance,
          project: null,
          projectCanvas: null,
          projectPhase: null,
          projectItem: null,
          appCustomConfig,
          additionalContext,
          isCustomNavigation,
        }),
        sideEffectNode: null,
      }
    } else {
      if (isProjectOnly) {
        console.error(`App '${app.name}' can only be launched in a project`)
      }
      if (!isHierarchyPathValid) {
        console.error(`Invalid selected hierarchy for app '${app.name}'`)
      }
    }

    return getInvalidResolvedAppData()
  }

  return null
}
