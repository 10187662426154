import { MayBeNull, OpenMenuHandler } from '@wpp-open/core'
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react'

import { useMiroConfigStatusApi } from 'api/miro/queries/useMiroConfigStatusApi'
import { useTrackAppOpenAnalytics } from 'hooks/useTrackAppOpenAnalytics'
import { useAppDataFromUrl } from 'providers/osState/utils/useAppDataFromUrl'
import { useNavigationState } from 'providers/osState/utils/useNavigationState'
import { useResolveAppData } from 'providers/osState/utils/useResolveAppData'
import { MiroConfigStatusResponse } from 'types/miro'
import { AppDataFromUrl } from 'types/osState/appDataFromUrl'
import { AppData } from 'types/osState/appDataResolved'
import { AppLaunchData } from 'types/osState/appLaunchData'

export interface OsStateContextValue {
  isNavigationOpen: boolean
  preselectedHierarchyNodeId: MayBeNull<string>
  appDataDebug: AppDataFromUrl // This is logged to console in case of invalid app launch
  appData: AppData
  openNavigation: OpenMenuHandler
  closeNavigation: () => void
  miroConfigStatus: MayBeNull<MiroConfigStatusResponse>
  appLaunchData: MayBeNull<AppLaunchData>
  setAppLaunchData: Dispatch<SetStateAction<MayBeNull<AppLaunchData>>>
}

export const OsStateContext = createContext<OsStateContextValue>(null!)

export const useOsState = () => useContext(OsStateContext)

export const OsStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const { isNavigationOpen, preselectedHierarchyNodeId, openNavigation, closeNavigation } = useNavigationState()
  const appDataShort = useAppDataFromUrl()
  const [appLaunchData, setAppLaunchData] = useState<MayBeNull<AppLaunchData>>(null)

  const { data: miroConfigStatus } = useMiroConfigStatusApi()

  const { appData, sideEffectNode } = useResolveAppData({ appDataShort })

  useTrackAppOpenAnalytics({ appData, appLaunchData, setAppLaunchData })

  return (
    <OsStateContext.Provider
      value={{
        isNavigationOpen,
        preselectedHierarchyNodeId,
        appDataDebug: appDataShort,
        appData,
        openNavigation,
        closeNavigation,
        miroConfigStatus,
        appLaunchData,
        setAppLaunchData,
      }}
    >
      {sideEffectNode}

      {children}
    </OsStateContext.Provider>
  )
}
