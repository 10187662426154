import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { useHomeUrl } from 'hooks/useHomeUrl'
import { NoCodeAppExternalLink } from 'types/apps/noCodeApps'
import { simulateLinkClick } from 'utils/links'

export const OsStateExternalLink = ({ app }: { app: NoCodeAppExternalLink }) => {
  const homeUrl = useHomeUrl()

  useEffect(() => {
    simulateLinkClick({ href: app.url, target: '_blank' })
  }, [app.url])

  return <Navigate replace to={homeUrl} />
}
