import 'app/App.scss'

import { useMemo } from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

import { usePopulateConfigurableCssVariables } from 'app/utils'
import { RootRenderErrorBoundary } from 'components/renderError'
import { SmallScreenBoundary } from 'components/smallScreenBoundary/SmallScreenBoundary'
import { usePerformanceLogs } from 'hooks/usePerformanceLogs'
import { OsQueryClientProvider } from 'providers/osQueryClient'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { OsRootProvider } from 'providers/osRoot/OsRootProvider'
import { OsSupportServiceProvider } from 'providers/osSupportService/OsSupportServiceProvider'
import { PublicDataProvider } from 'providers/publicData/PublicDataProvider'
import { ToastProvider } from 'providers/toast/ToastProvider'
import { getAppRoutes } from 'routes'

const AppRouter = () => {
  const { hostInfo } = useOsRoot()

  const router = useMemo(() => {
    const routeElements = getAppRoutes(hostInfo)

    return createBrowserRouter(createRoutesFromElements(routeElements))
  }, [hostInfo])

  return (
    <RouterProvider
      // Key is important for dev mode to re-create the whole router in generic flow simulation.
      {...(process.env.DEV && { key: hostInfo.type })}
      router={router}
      future={{ v7_startTransition: true }}
    />
  )
}

export const App = () => {
  usePopulateConfigurableCssVariables()
  usePerformanceLogs()

  return (
    <RootRenderErrorBoundary>
      <ToastProvider>
        <OsQueryClientProvider>
          <OsRootProvider>
            <SmallScreenBoundary>
              <PublicDataProvider>
                <OsSupportServiceProvider>
                  <AppRouter />
                </OsSupportServiceProvider>
              </PublicDataProvider>
            </SmallScreenBoundary>
          </OsRootProvider>
        </OsQueryClientProvider>
      </ToastProvider>
    </RootRenderErrorBoundary>
  )
}
