import { facadeApi } from 'api'

interface Params {
  hubId: string
  requestReason: string
}

export const createHubAccessRequestApi = ({ hubId, requestReason }: Params) =>
  facadeApi.post('/hub-access-requests', {
    hubId,
    requestReason,
  })
